import React from "react"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      Hello World!,
      <br />
      I'm Milad,
      <br />
      Front-end Developer.
    </>
  )
}
